<template>
  <el-card>
    <template #header>
      <div class="flex_between">
        <div class="flex_start">
          <span class="main-title mt-20">后测任务列表</span>
          <el-button type="primary" size="small" @click="creatingTasks"
            >创建任务</el-button
          >
        </div>
        <el-button
          type="primary"
          class="mt-20"
          size="small"
          :disabled="!multipleSelection.length"
          @click="batchDown"
          >批量下载</el-button
        >
      </div>
    </template>
    <el-table
      ref="tableRef"
      class="hctable"
      stripe
      v-loading="loading"
      :row-key="getRowKeys"
      :data="tableData"
      :default-sort="{ prop: 'insertTime', order: 'descending' }"
      :height="tableHeight"
      @select="selectChange"
      @sort-change="sortChange"
    >
      <el-table-column
        type="selection"
        align="center"
        min-width="2%"
        :selectable="selectable"
        :reserve-selection="true"
      />
      <el-table-column
        prop="taskName"
        align="center"
        label="任务名称"
        min-width="10%"
      />
      <el-table-column
        prop="taskDesc"
        align="center"
        label="任务说明"
        min-width="10%"
      />
      <el-table-column
        prop="condition"
        align="center"
        label="筛选条件"
        min-width="25%"
      >
        <template #default="{ row }">
          <div class="condition">{{ row.condition }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="resultType"
        align="center"
        label="结果类型"
        min-width="9%"
      >
        <template #default="{ row }">
          {{ row.resultEctype }}
        </template>
      </el-table-column>
      <el-table-column
        sortable="custom"
        prop="insertTime"
        align="center"
        label="创建时间"
        min-width="14%"
      />
      <el-table-column
        sortable="custom"
        prop="finishTime"
        align="center"
        label="完成时间"
        min-width="14%"
      />
      <el-table-column prop="status" align="center" label="状态" min-width="5%">
        <template #default="{ row }">
          <span
            v-if="row.status !== 3"
            :style="{ color: listStatus[row.status].color }"
          >
            {{ listStatus[row.status].text }}</span
          >
          <el-tooltip
            v-else
            effect="dark"
            :content="row.failReason"
            placement="top-end"
          >
            <span :style="{ color: listStatus[row.status].color }">
              {{ listStatus[row.status].text }}</span
            >
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="failReason"
        align="center"
        label="下载"
        min-width="5%"
      >
        <template #default="{ row }">
          <el-icon
            :class="[
              { disabled: !row.resultFileName || row.status !== 2 },
              'size22',
            ]"
            @click="downResult(row)"
          >
            <Download />
          </el-icon>
        </template>
      </el-table-column>

      <el-table-column prop="delete" align="center" label="删除" min-width="4%">
        <template #default="{ row }">
          <el-icon
            :class="[{ disabled: row.status === 1 }, 'size20']"
            @click="operationShow(row, 'delete')"
          >
            <Delete />
          </el-icon>
        </template>
      </el-table-column>

      <el-table-column prop="delete" align="center" label="重跑" min-width="4%">
        <template #default="{ row }">
          <el-icon
            v-if="row.status == 3"
            :class="[{ disabled: row.status === 1 }, 'size20']"
            @click="operationShow(row, 'reset')"
          >
            <svg-icon className="resetTask"></svg-icon>
          </el-icon>
        </template>
      </el-table-column>
    </el-table>
    <div class="flex_end mt-12">
      <span>共 {{ page.count || 0 }} 条</span>
      <el-pagination
        :current-page="page.current"
        :page-size="page.size"
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :total="page.count || 1"
      />
    </div>
  </el-card>

  <el-dialog
    v-model="dialogVisible"
    :title="operation[operationType]"
    width="550px"
  >
    <span>是否{{ operation[operationType] }}该任务？</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="operationFun(row)"> 确定 </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { sovList } from "@/api/tool";
import { useRouter } from "vue-router";
import { sovResult, sovTask, resetTask } from "@/api/tool";
import { Delete, Download } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";

const router = useRouter();

const loading = ref(false);
const tableData = ref([]);

//生成结果弹窗数据
const dialogVisible = ref(false);

const listStatus = {
  0: {
    text: "未启动",
    color: "#d8d8d8",
  },
  1: {
    text: "进行中",
    color: "#FDD100",
  },
  2: {
    text: "成功",
    color: "#67C23A",
  },
  3: {
    text: "失败",
    color: "#FF0000",
  },
  4: {
    text: "失败",
    color: "#FF0000",
  },
};

// operation
const operation = {
  delete: "删除",
  reset: "重跑",
};

//分页器数据
const page = reactive({
  current: 1,
  size: 10,
  count: 0,
  prop: "insertTime",
  order: "descending",
});

const listResult = (detail) => {
  return {
    1: "全量发帖数据",
    2: `TOP ${detail} 发帖数据`,
    3: "维度统计数据",
    4: "品牌人群画像",
    5: "品牌KOL投放结构",
  };
};

const tableHeight = ref(window.innerHeight - 280);
window.addEventListener("resize", () => {
  tableHeight.value = window.innerHeight - 280;
});

const multipleSelection = ref([]);
const currentId = ref("");
const operationType = ref("delete");
const tableRef = ref();


onMounted(() => {
  getSovList();

})

const getSovList = async (order, prop) => {
  loading.value = true;
  const { data } = await sovList({
    page: page.current,
    size: page.size,
    sortColumn: prop === "finishTime" ? "finishTime" : "insertTime",
    sortDirection: order === "ascending" ? "asc" : "desc",
  });
  loading.value = false;
  page.count = data.total;
  tableData.value = data.table.map((item) => {
    item.resultType = JSON.parse(item.resultType);
    item.resultEctype = listResult(item.resultType.detail)[
      item.resultType.type
    ];
    return item;
  });
};



//分页器方法
const handleCurrentChange = (val) => {
  page.current = val;
  getSovList(page.order, page.prop);
};

//排序方法
const sortChange = (column) => {
  page.order = column.order;
  page.prop = column.prop;
  handleCurrentChange(1);
};

const selectChange = (val) => {
  multipleSelection.value = val;
};


// 下载
const downResult = async (row) => {
  if (!row.resultFileName || row.status !== 2) return;
  const startDate = new Date()
  ElMessage('任务正在下载')
  const data = await sovResult({ fileName: row.resultFileName });
  const fileName = `${row.taskName}.xlsx`;
  const blob = new Blob([data]);
  const downloadElement = document.createElement("a");
  const href = window.URL.createObjectURL(blob);
  downloadElement.href = href;
  downloadElement.download = decodeURI(fileName);
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
  window.URL.revokeObjectURL(href);
  const endDate = new Date()
  if (endDate.getTime() - startDate.getTime() > 3000) {
    ElMessage.success('任务下载完成')
  }
};

//批量循环下载
const batchDown = () => {
  multipleSelection.value.forEach((v, i) => {
    if (i > 0) {
      setTimeout(() => {
        downResult(v);
      }, 2000);
    } else {
      downResult(v);
    }
  });
};

const operationShow = (row, type) => {
  operationType.value = type;
  dialogVisible.value = true;
  currentId.value = row.id;
};

const operationFun = async () => {
  if (operationType.value === "delete") {
    await sovTask({ id: currentId.value });
    multipleSelection.value = multipleSelection.value.filter(
      //如果删除的任务被勾选，删除勾选数组里的值。
      (v) => v.id !== currentId.value
    );
    const nonexistent = tableData.value.find((v) => v.id === currentId.value); //拿到删除的任务，设置为取消勾选
    if (nonexistent) {
      tableRef.value.toggleRowSelection(nonexistent, false);
    }
  } else {
    await resetTask({ id: currentId.value });
  }
  dialogVisible.value = false;
  handleCurrentChange(1)
  ElMessage.success(
    `${operation[operationType.value] === "删除" ? "删除成功" : "该任务已重跑"
    }`
  );
};

//表格列按逻辑禁止勾选
const selectable = (row) => {
  if (!row.resultFileName || row.status !== 2) {
    return false;
  } else {
    if (multipleSelection.value.length >= 5) {
      const result = multipleSelection.value.findIndex((v) => v.id === row.id);
      if (result >= 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
};

//翻页保留勾选项
const getRowKeys = (row) => {
  return row.id;
};


//创建任务按钮
const creatingTasks = () => {
  router.push({
    name: 'HcCreatingTasks'
  })
}

</script>

<style lang="scss" scoped>
.el-card {
  width: 100%;
  height: 100%;
}
.tool-wrap {
  width: 100%;
  height: 100%;

  .el-card {
    height: 100% !important;
  }

  .box-card {
    border-radius: 5px;
  }

  .el-button--primary {
    height: 30px;
    background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
  }
}

:deep(.el-card) {
  width: 100%;
  height: 100% !important;
}
.pagination {
  margin-top: var(--el-card-padding);
}

.main-title {
  font-size: 18px;
  font-weight: 800;
}

thead {
  th {
    .cell {
      color: #60738c;
    }
  }
}

.condition {
  text-align: left;
  white-space: pre-wrap;
}

.el-button--primary {
  height: 30px;
  background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
}

.ant-form-item-label > label::after {
  content: "";
}

.el-upload__input {
  display: none !important;
}

.el-picker-panel__sidebar {
  width: 124px !important;
}

.tool-wrap.hc {
  .el-radio-button__inner {
    background-color: #f8f9fe;
  }

  .el-radio-button__inner:hover {
    color: #606266;
  }

  :deep(.pagination) {
    li.active.number {
      background-color: #e09a29 !important;
    }
  }
}
</style>
<style lang="scss">
.hctable {
  .el-table__row {
    td:nth-child(4) {
      .cell {
        text-align: left;
      }
    }

    td:nth-child(9),
    td:nth-child(10) {
      .cell {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .size22 {
      font-size: 22px;
      cursor: pointer;
    }

    .size20 {
      font-size: 20px;
      cursor: pointer;
    }

    .disabled {
      color: #d8d8d8;
      cursor: no-drop;
    }
  }

  .el-table__header {
    thead {
      tr:first-child {
        th:first-child {
          .cell {
            display: none;
          }
        }
      }
    }
  }
}
</style>


